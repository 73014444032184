import { Controller } from "@hotwired/stimulus";
import videojs from "video.js";

export default class extends Controller {
  static targets = ["videoContainer"];

  connect() {}

  watchVideo(e) {
    const { topicId, videoUrl } = e.currentTarget.dataset;

    e.currentTarget.classList.toggle('d-none');
    this.videoContainerTarget.classList.toggle('d-none');

    // videojs(container, {}, function onPlayerReady() {
    //   videojs.log("Your player is ready!");

    //   // In this context, `this` is the player that was created by Video.js.
    //   this.play();

    //   // How about an event listener?
    //   this.on("ended", function () {
    //     videojs.log("Awww...over so soon?!");
    //   });
    // });
  }
}
